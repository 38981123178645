import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { Machine, assign } from 'xstate';
import Gate from './Gate';
import WatchPartyRoom from './WatchPartyRoom';
import WaitingRoom from '../WaitingRoom';
import CheckWaitStatusMachine from '../WaitingRoom/CheckWaitStatusMachine';
import { Box } from 'grommet';
import gtag from 'ga-gtag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const RoomMachine = Machine({
    id: 'RoomMachine',
    'initial': 'loadRoom',
    'states': {
        loadRoom: {
            invoke: {
                src: 'loadRoom',
                onDone: [
                    {
                        cond: 'roomDoesNotExist',
                        target: 'showRoomDoesNotExist',
                    },
                    {
                        cond: 'sessionAlreadyAuthenticated',
                        actions: ['saveRoomInfoToContext'],
                        target: 'showRoom',
                    },
                    {
                        actions: ['saveRoomInfoToContext'],
                        target: 'showGate',
                    },
                ],
                onError: {
                    target: 'showError',
                },
            }
        },
        showGate: {
            on: {
                SUCCESSFUL_AUTH: [
                    {
                        cond: 'directedToWaitingRoom',
                        actions: ['saveStatusCheckURLToContext'],
                        target: 'showWaitingRoom',
                    },
                    {
                        actions: ['saveRoomInfoToContext'],
                        target: 'showRoom',
                    }
                ],
            }
        },
        showWaitingRoom: {
            invoke: {
                src: CheckWaitStatusMachine,
                data: {
                    statusCheckURL: (context:any) => context.statusCheckURL,
                },
                onDone: {
                    actions: ['saveRoomInfoToContext'],
                    target: 'showRoom',
                },
                onError: {
                    target: 'showError',
                }
            }
        },
        showRoom: {

        },
        showRoomDoesNotExist: {

        },
        showError: {

        }
    },
});



const Room:FC<{}> = props => {
    let { publicId } = useParams<{publicId: string}>();
    const [state, send] = useMachine(RoomMachine, {
        actions: {
            saveRoomInfoToContext: assign({
                roomInfo: (_, event: any) => {
                    if (event.data.roomInfo) {
                        return event.data.roomInfo;
                    }
                    
                    return event.data;
                },
            }),
            saveStatusCheckURLToContext: assign({
                statusCheckURL: (_, event: any) => {
                    return event.data.check_url;
                }
            })
        },
        services: {
            loadRoom: async context => {
                for (let i = 0; i < 3; i++) {
                    const res = await fetch(`/api/rooms/${publicId}`);
                    if (res.ok) {
                        return res.json();
                    } else if (res.status === 404) {
                        return;
                    }
                }

                return Promise.reject(new Error('Could not retrieve room info from API!'));
            }
        },
        guards: {
            sessionAlreadyAuthenticated: (context, event: any) => {
                return event.data.stream_channel_id !== undefined;
            },
            roomDoesNotExist: (context, event: any) => {
                return event.data === undefined;
            },
            directedToWaitingRoom: (context, event: any) => {
                return event.data.waiting === true;
            },
        }
    });

    // set document title
    if (state.context.roomInfo) {
        const documentTitle = `${state.context.roomInfo.party ? state.context.roomInfo.party.name + " -" : ""} ${state.context.roomInfo.name}`;
        document.title = documentTitle;
        gtag('event', 'page_view', {
            page_title: documentTitle
        });
    } else {
        document.title = 'StartWatchParty.com';
    }

    if (state.matches('loadRoom')) {
        return (
            <Box height="100vh">
                <Box fill pad="medium" align="center" justify="center">
                    <FontAwesomeIcon icon={faSpinner} spin className="mb-2" />
                    Loading room {publicId}...
                </Box>
            </Box>
        );
    } else if (state.matches('showGate')) {
        return (
            <Gate
                roomInfo={state.context.roomInfo}
                onSuccessfulAuth={data => send('SUCCESSFUL_AUTH', {data})}
            />
        );
    } else if (state.matches('showWaitingRoom')) {
        return (
            <WaitingRoom
                roomInfo={state.context.roomInfo}
            />
        );
    } else if (state.matches('showRoom')) {
        return (
            <WatchPartyRoom
                roomInfo={state.context.roomInfo}
            />
        );
    } else if (state.matches('showRoomDoesNotExist')) {
        return (
            <Box height="100vh">
                <Box fill pad="medium" align="center" justify="center">
                    No such room. Maybe the room does not exist?
                </Box>
            </Box>
        );
    } else {
        return (
            <Box height="100vh">
                <Box fill pad="medium" align="center" justify="center">
                    A (probably transient) error occurred attempting to load the room. Please refresh the page to try again.
                </Box>
            </Box>
        );
    }
};

export default Room;
