import React, { FC } from 'react';
import { Box, Heading } from 'grommet';

const Front : FC<{}> = props => {
    return (
        <>
        <Box height="100vh">
            <Box fill pad="medium" align="center" justify="center">
                <img 
                    src="/images/watchlogo.svg" 
                    height="100"
                    width="100" 
                    alt="Start Watch Party logo"
                />
                <Heading>StartWatchParty.com</Heading>
                There's nothing here yet.<br/>
                Get your room code from your room admin!
            </Box>
        </Box>
        </>
    )
};

export default Front;
