import { knuthShuffle } from 'knuth-shuffle';

const HeartsAnimation = (container) => {
    // heart class 
    class Heart {
        constructor( x, y, color, shape = 'heart' ) {
            this.x = parseFloat( x || container.clientWidth - 50 );
            this.y = parseFloat( y || container.clientHeight - 30 );
            this.color = color || Math.floor( Math.random() * 360 );
            this.phase = Math.random() * 360;
            this.radius = Math.random() * 1;
            this.speed = 1 + Math.random() * 2;
            this.scale = 0.7 + Math.random() * 0.8;
            this.grow = 0.01;
            this.alpha = 1;
            this.done = false;
             
            this.outer = document.createElement( "div" );
            this.outer.className = "reaction-outer";
             
            this.inner = document.createElement( "div" );
            if (shape === HeartShapes.HEART) {
               this.inner.className = "reaction-inner heart";
               // this.inner.style.backgroundColor = "hsl( "+ this.color +", 50%, 50% )";
            } else if (shape === HeartShapes.AMEN) {
                this.inner.className = "reaction-inner amen";
            } else if (shape === HeartShapes.AMENPSPRINCE) {
                this.inner.className = "reaction-inner amenpsprince";
            } else if (shape === HeartShapes.BAM) {
                this.inner.className = "reaction-inner bam";
            } else if (shape === HeartShapes.CRY) {
                this.inner.className = "reaction-inner cry";
            } else if (shape === HeartShapes.DSAM) {
                this.inner.className = "reaction-inner dsam";
            } else if (shape === HeartShapes.OHSLAIN) {
                this.inner.className = "reaction-inner ohslain";
            } else if (shape === HeartShapes.PREACHIT) {
                this.inner.className = "reaction-inner preachit";
            } else if (shape === HeartShapes.OOOF) {
                this.inner.className = "reaction-inner ooof";
            } else if (shape === HeartShapes.WOW) {
                this.inner.className = "reaction-inner wow";
            } else if (shape === HeartShapes.SOGOOD) {
                this.inner.className = "reaction-inner sogood";
            } else if (shape === HeartShapes.CLAPPING) {
                this.inner.className = "reaction-inner clapping";
            } else {
                return;
            }

            this.outer.appendChild( this.inner ); 
            container.appendChild( this.outer ); 
            this.draw();
        }
        
        flush() {
            if( container.contains( this.outer ) ) {
                container.removeChild( this.outer );
            }
            this.outer = null; 
            this.inner = null; 
        }
 
        draw() {
            if( this.done ) return; 
            this.outer.style.transform = "translateX( "+ this.x +"px ) translateY( "+ this.y +"px ) translateZ( 0 ) scale( "+ this.grow +" )";
            this.outer.style.opacity = this.alpha; 
        }
        
        update() {
            this.alpha = ( this.alpha > 0 ) ? ( this.alpha - 0.0015 ) : this.alpha; 
            this.alpha = ( this.alpha < 0 ) ? 0 : this.alpha; 
             
            this.x += Math.cos( this.phase / 50 ) * this.radius;
            this.y -= this.speed; 
             
            this.grow += ( this.scale - this.grow ) / 10;
            this.phase += 1;
             
            this.done = ( this.y < -100 || this.alpha <= 0 ) ? true : false;    
        }
    }
 
 
    // hearts list
    let hearts = [];

    function getCurrentReactionsCount() {
        return hearts.length;
    }

    /*
    hearts.push( new Heart() ); 
    hearts.push( new Heart() ); 
    hearts.push( new Heart() ); 

    // add on click 
    triggerElement.addEventListener( "click", ( e ) => {
        for (let i = 0; i < 2; i++) {
                hearts.push( new Heart() ); 
        }
    });

    // auto add on interval 
    let id = setInterval( () => {
        hearts.push( new Heart() ); 
    }, 1500 );

    setTimeout(function() {
        clearInterval(id);
    }, 10000);
    */
        
    // main loop 
    function loop() {
        requestAnimationFrame( loop );
        let i; 

        // cleanup 
        for( i = 0; i < hearts.length; ++i ) {
            if( hearts[i].done ) {
                hearts[i].flush();
                hearts.splice( i, 1 ); 
            }
        }
        // animate 
        for( i = 0; i < hearts.length; ++i ) {
            hearts[i].update(); 
            hearts[i].draw(); 
        }
    }
 
    loop();

    const MAX_REACTIONS_ON_SCREEN = 20;
    return {
        addReaction: shape => {
            if (hearts.length < MAX_REACTIONS_ON_SCREEN) {
                hearts.push(new Heart(undefined, undefined, undefined, shape));
            }
        },
        spamReactions: reactions => {
            const currentReactionsCount = getCurrentReactionsCount();
            if (currentReactionsCount > MAX_REACTIONS_ON_SCREEN) return; // don't spam too many reactions
            
            const reactionsToApply = [];
            for (const [key, value] of Object.entries(reactions)) {
                if (Number.isInteger(value)) {
                    for (let i = 0; i < value; i++) {
                        reactionsToApply.push(key);
                    }
                }
            }

            // shuffle the reactionsToApply array
            const shuffledReactionsToApply = knuthShuffle(reactionsToApply.slice(0));

            // how many new reactions can we add?
            const numNewReactionsToAdd = MAX_REACTIONS_ON_SCREEN - currentReactionsCount;
            const pickedReactionsToApply = shuffledReactionsToApply.slice(0, numNewReactionsToAdd);

            const max = 2000;
            const min = 100;
            for (let i = 0; i < pickedReactionsToApply.length; i++) {
                const reactionShape = pickedReactionsToApply[i];
                const delay = Math.random() * (max - min) + min;
                setTimeout(() => {
                        hearts.push(new Heart(undefined, undefined, undefined, reactionShape));
                }, delay);
            }
        },
    }
}


export default HeartsAnimation;
export let HeartShapes = {
    HEART: 'HEART',
    AMEN: 'AMEN',
    AMENPSPRINCE: 'AMENPSPRINCE',
    BAM: 'BAM',
    CRY: 'CRY',
    DSAM: 'DSAM',
    OHSLAIN: 'OHSLAIN',
    PREACHIT: 'PREACHIT',
    OOOF: 'OOOF',
    WOW: 'WOW',
    SOGOOD: 'SOGOOD',
    CLAPPING: 'CLAPPING',
};
