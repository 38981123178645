import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Front from './Front';
import Room from './Room';
import './App.scss';
import { Grommet } from 'grommet';
import { WatchPartyTheme } from './theme';
import Toast from './common/toast';
import { install } from 'ga-gtag';

install('G-NMZX1B6ELQ');

const App:FC<{}> = props => {
    return (
        <Grommet theme={WatchPartyTheme}>
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <Front />
                    </Route>
                    <Route path="/r/:publicId">
                        <Room />
                    </Route>
                </Switch>
            </Router>
            <Toast />
        </Grommet>
    );
}

export default App;
