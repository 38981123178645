import { Machine, assign } from 'xstate';

export default Machine({
    id: 'CheckWaitStatusMachine',
    context: {
        statusCheckURL: '',
    },
    initial: 'wait',
    states: {
        wait: {
            after: {
                2000: 'check',
            }
        },
        check: {
            invoke: {
                src: 'triggerStatusCheck',
                onDone: [
                    {
                        cond: 'stillNeedsToWait',
                        target: 'wait',
                    },
                    {
                        target: 'doneWaiting',
                        actions: assign({ roomInfo: (context, event) => event.data }),
                    }
                ],
                onError: {
                    target: 'wait',
                }
            }
        },
        doneWaiting: {
            type: 'final',
            data: {
                roomInfo: (context: any) => context.roomInfo,
            }
        },
    }
}, {
    services: {
        triggerStatusCheck: async (context:any) => {
            const response = await fetch(context.statusCheckURL);
            if (!response.ok) {
                throw new Error('An error occurred attempting to perform waiting room status check!');
            }

            const json = await response.json();
            return json;
        },
    },
    guards: {
        stillNeedsToWait: (context, event: any) => {
            return event.data.waiting === true;
        },
    }
});