import React, { FC, useState, useEffect } from 'react';
import { Channel, ChannelMemberResponse } from 'stream-chat';
import { Box, Heading } from 'grommet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import ButtonWithState from '../common/ButtonWithState';
import Toast from '../common/toast';
import gtag from 'ga-gtag';
import ColorHash from 'color-hash';
let colorHash = new ColorHash();
interface MemberListProps {
    currentChatUserID: string,
    roomID: string,
    channel: Channel,
    members: any[],
    enableModeratorFunctions?: boolean,
}

const MemberList:FC<MemberListProps> = ({currentChatUserID, roomID, channel, members, enableModeratorFunctions}) => {
    const [mutedMembers, setMutedMembers] = useState<string[]>([]);

    useEffect(() => {
        const theFn = async () => {
            if (enableModeratorFunctions) {
                const mm = await getMutedMembers(roomID);
                setMutedMembers(mm.map((m:any) => m.muted_chat_user_id));
            }
        };

        theFn();
    }, [channel, roomID, enableModeratorFunctions]);

    const getMutedMembers = async (roomID: string) => {
        const mm = await fetch(`/api/rooms/${roomID}/muted-members`).then(res => res.json()).then(json => Promise.resolve(json));
        return mm;
    };

    const muteMember = async (member: ChannelMemberResponse) => {
        if (!enableModeratorFunctions) return;

        gtag('event','select_content', {
            'content_type': 'Moderation',
            'item_id': 'mute'
        });

        const csrfToken = await fetch('/api/csrf').then(res => res.json()).then(json => Promise.resolve(json.token));
        const success = await fetch(`/api/rooms/${roomID}/muted-members`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                '_token': csrfToken,
                'mute_chat_user_id': member.user?.id,
            }),
        }).then(res => Promise.resolve(res.ok));

        if (success) {
            // refresh muted member list
            Toast.toast(`${member.user?.name} muted`);
            const mm = await getMutedMembers(roomID);
            setMutedMembers(mm.map((m:any) => m.muted_chat_user_id));
        }
    };

    const unmuteMember = async (member: ChannelMemberResponse) => {
        if (!enableModeratorFunctions) return;

        gtag('event','select_content', {
            'content_type': 'Moderation',
            'item_id': 'unmute'
        });

        const csrfToken = await fetch('/api/csrf').then(res => res.json()).then(json => Promise.resolve(json.token));
        const success = await fetch(`/api/rooms/${roomID}/muted-members/${member.user?.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                '_token': csrfToken,
                'mute_chat_user_id': member.user?.id,
            }),
        }).then(res => Promise.resolve(res.ok));

        if (success) {
            // refresh muted member list
            Toast.toast(`${member.user?.name} unmuted`);
            const mm = await getMutedMembers(roomID);
            setMutedMembers(mm.map((m:any) => m.muted_chat_user_id));
        }
    };

    return (
        <Box pad="medium" border="top" style={{'minHeight': 'initial', 'fontSize': '14px'}}>
            <Heading level="5">Participants in the Room</Heading>
            {members.filter(m => {
                return m.hasOwnProperty('user');
            }).map((member, index) => {
                const isMuted = mutedMembers.includes(member.user!.id);
                const userColor = colorHash.hsl(member.user?.id);
                return (
                    <Box style={{minHeight: '44px'}} direction="row" justify="between" background={`hsl(${userColor[0]},${userColor[1]*100}%,20%)`} pad={{vertical: '5px', horizontal: '10px'}} key={member.user?.id} responsive={false}>
                        <Box direction="row" alignSelf="center" style={{color: isMuted ? 'red' : 'inherit'}}>
                            {!member.is_moderator && enableModeratorFunctions && (
                                isMuted && (
                                    <span><FontAwesomeIcon icon={faCommentSlash}/>&nbsp;</span>
                                )
                            )}
                            {member.user?.name} {currentChatUserID === member.user?.id ? '(Me)' : ''} {member.is_moderator ? '(Host)' : ''} 
                        </Box>
                        <Box direction="row" alignSelf="center">
                        {!member.is_moderator && enableModeratorFunctions && (
                            <>
                                <ButtonWithState 
                                onClick={() => {
                                    if (isMuted) {
                                        unmuteMember(member);
                                    } else {
                                        muteMember(member);
                                    }
                                }} 
                                label={isMuted ? 'UNMUTE' : 'MUTE'} />
                            </>
                        )}
                        </Box>
                    </Box>
                );
            })}
            <div className="mb-4">&nbsp;</div>
        </Box>
    )
};

export default MemberList;
