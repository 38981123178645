import { Machine } from 'xstate';

export default Machine({
    id: 'HeartSendingDebounceMachine',
    context: {
        HEART: 0,
        AMEN: 0,
        AMENPSPRINCE: 0,
        BAM: 0,
        CRY: 0,
        DSAM: 0,
        OHSLAIN: 0,
        PREACHIT: 0,
        OOOF: 0,
        WOW: 0,
        SOGOOD: 0,
        CLAPPING: 0
    },
    initial: 'idle',
    states: {
        idle: {
            exit: ['clearContext'],
            on: {
                HEART: {
                    actions: ['addHearts'],
                    target: 'wait',
                }
            }
        },
        wait: {
            on: {
                HEART: {
                    actions: ['addHearts'],
                    target: 'wait',
                }
            },
            after: {
                1000: {
                    actions: ['sendHearts'],
                    target: 'idle',
                }
            }
        }
    }
});
