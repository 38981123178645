import React from 'react';
import { Box, Button, Layer, Text } from 'grommet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default class Toast extends React.Component {
    static ToastInstance

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            message: null,
        };

        Toast.ToastInstance = this;
        this.handleDismiss = this.handleDismiss.bind(this);
        this.timer = null;
    }

    // static function to be called by other compnents to trigger _show
    static toast(title, message, buttons, options) {
        Toast.ToastInstance._show(title, message, buttons, options);
    }
    
    _show(message) {
        this.setState({ 
            visible: true, 
            message: message,
        });
        this.timer = setTimeout(() => this.handleDismiss(), 3500);
    }
    
    handleDismiss() {
        this.setState({ visible: false });
    }

    componentDidMount(){
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        return (
            <>
            {this.state.visible && (
                <Layer
                position="bottom"
                modal={false}
                margin={{ vertical: 'medium', horizontal: 'small' }}
                onEsc={this.handleDismiss}
                responsive={false}
                plain
                >
                    <Box
                        align="center"
                        direction="row"
                        gap="small"
                        justify="between"
                        elevation="medium"
                        pad={{ vertical: 'xsmall', horizontal: 'small' }}
                        background="status-ok"
                    >
                        <Box align="center" direction="row" gap="xsmall">
                        <Text>{this.state.message}</Text>
                        </Box>
                        <Button icon={<FontAwesomeIcon icon={faTimes} />} onClick={this.handleDismiss} plain />
                    </Box>
                </Layer>
            )}
            </>
        )
    }
}

