import React, { useState,useEffect } from 'react';
import { Button } from 'grommet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ButtonWithState = (props) => {
    const [isClicked, setIsClicked] = useState(false);
    const { onClick, label, ...otherProps } = props;

    const handleOnClick = () => {
        if(!isClicked){
            onClick();
        }
        setIsClicked(true);
    }

    useEffect(() => {
        setIsClicked(false);
    },[props.onClick]);

    return (
        <Button 
            size="small"
            primary
            onClick={handleOnClick}
            {...otherProps}
            style={{padding: '4px 12px', textTransform: 'uppercase', opacity: isClicked ? '0.5' : '1.0'}}
        >
            {isClicked ? <FontAwesomeIcon icon={faSpinner} spin /> : label}
        </Button>
    )
}

export default ButtonWithState;
