import { deepMerge } from 'grommet/utils';
import { dark } from 'grommet/themes';

export const WatchPartyTheme = deepMerge(dark, {
  global: {
    rounding: 4,
    colors: {
      brand: '#37647D',
      background: '#2C2C2C',
    },
    font: {
        family: 'Work Sans',
        size: '16px',
        height: '20px',
      },
  },
});
