import React, { FC, useState, useEffect, useCallback } from 'react';
import { Chat, Channel, ChannelHeader, Thread, Window, MessageList, MessageLivestream, MessageInput, MessageInputSmall } from 'stream-chat-react';
import { StreamChat, Channel as ChatChannel, ChannelMemberResponse } from 'stream-chat';
import WaitingRoomModeratorView from '../WaitingRoom/ModeratorView';
import MemberList from './MemberList';
import 'stream-chat-react/dist/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Hearts/hearts.scss';
import HeartsApp, { HeartShapes } from '../Hearts/hearts';
import { Box, Menu } from 'grommet';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTimes, faReply, faEllipsisH, faWindowMaximize, faExpandAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from '../common/windowDimensions.js';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import HeartSendingDebounceMachine from '../Hearts/HeartSendingDebounceMachine';
import '../Hearts/hearts.scss'
//import SpamHeartsThrottleMachine from '../Hearts/SpamHeartsThrottleMachine';
 // @ts-ignore
import GiphyKeyboard from '../common/GiphyKeyboard';
import Toast from '../common/toast';
import gtag from 'ga-gtag';
import ColorHash from 'color-hash';
import {RoomInfo} from '../types';

let colorHash = new ColorHash();

interface RowContainerProps {
    readonly keyboardMarginOffset: any;
};

const RowContainer = styled.div<RowContainerProps>`
    background-image: url(/images/background-blurred.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    height: 100vh;

    @media (max-width: 767px) {
        flex-direction: column;
        height: 100%;
        width: 100%;
        position: fixed;
        flex-wrap: nowrap;
        top: ${props => props.keyboardMarginOffset + 'px'};
    }
`

interface ChannelHeaderWrapperProps {
    readonly messageInputFocused: boolean;
};

const ChannelHeaderWrapper = styled.div<ChannelHeaderWrapperProps>`
    position: relative;

    @media (max-width: 767px) {
        display: ${props => props.messageInputFocused ? 'none' : 'block'};
    }
`

const VIcon = styled.div`
    position: absolute;
    top: 14px;
    left: 15px;
    border-radius: 4px;
    width: 42px;
    height: 42px;
    background: url('/images/vlogo.jpg');
    background-size: contain;
    z-index: 10;

    @media (max-width: 767px){
        top: 3px;
    }
`

const PartyCount = styled.div`
    position: absolute;
    top: 35.5px;
    left: 134px;
    z-index: 1;
    font-size: 14px;

    @media (max-width: 767px){
        top: 25px;
    }
`

const HideIcon = styled.div`
    font-size: 24px;
    position: absolute;
    top: 22px;
    right: 55px;
    cursor: pointer;
    z-index: 1;

    @media (max-width: 767px){
        top: 5px;
    }
`

const SettingsIcon = styled.div`
    font-size: 24px;
    position: absolute;
    top: 22px;
    right: 15px;
    cursor: pointer;
    z-index: 1;

    @media (max-width: 767px){
        top: 10px;
    }
`

const ChatMinimized = styled.div`
    font-size: 16px;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100px;
    cursor: pointer;
    z-index: 1;
    border-top-left-radius: 10px;
    background-color: #1f4287;;
    text-align: center;
    padding: 10px;
    color: #fff;
`

const VideoMinimized = styled.div`
    font-size: 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: 150px;
    cursor: pointer;
    z-index: 1;
    border-bottom-right-radius: 10px;
    background-color: #1f4287;
    text-align: center;
    padding: 10px;
    color: #fff;
`

interface ChatContainerProps {
    readonly messageInputFocused: boolean;
};

const ChatContainer = styled.div<ChatContainerProps>`
    background: #1a1a1a;
    height: 100vh;

    @media (max-width: 767px){
        height: auto;
        flex-grow: 1;
        position: relative;

        .str-chat {
            height: 100% !important;
            width: 100% !important;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
`

const GiphyKeyboardIcon = styled.div`
    width: 50px;
    height: 60px;
    background: url('/images/gif.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    margin-left: 5px;
`

const ReplyModal = styled.div`
    padding: 15px;
    position: relative;
    background: #262D31;
    border-top: 1px solid #333;
`

const ReplyModalClose = styled.div`
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 15px;
    display: flex;
    flex-direction: row;

    > button {
        margin-right: 15px;
    }
`

const ReplyModalCloseIcon = styled.div`
    width: 32px;
    height: 44px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface ReplyQuoteProps {
    readonly isParent: boolean;
    readonly userColor: any;
};

const ReplyQuote = styled.div<ReplyQuoteProps>`
    background-color: #20262B;
    color: #A4A6A8;
    padding: 7.5px 7.5px 0 7.5px;
    border-left: 5px solid hsl(${props => props.userColor[0]},${props => props.userColor[1]*100}%,50%);
    border-radius: 5px;
    margin-top: ${props => props.isParent ? '0px' : '7.5px'};
    margin-left: 0px;
    font-size: 14px;
    height: 62px;
    overflow: hidden;
`
interface ReplyUserProps {
    readonly userColor: any;
};

const ReplyUser = styled.div<ReplyUserProps>`
    font-weight: 700;
    color: hsl(${props => props.userColor[0]},${props => props.userColor[1]*100}%,50%);
    margin-bottom: 7.5px;
    font-size: 14px;
`

const ReplyMessage = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

interface MessageWrapperProps {
    readonly hasParent: boolean;
    readonly userColor: any;
};

const MessageWrapper = styled.div<MessageWrapperProps>`
    // on hover
    :hover {
        .startWatchParty__replyIcon {
            display: block;
        }
    }

    .str-chat__message-livestream {
        border: 0;
    }

    // chat bubble
    .str-chat__message-livestream-content {
        background-color: #262D31;
        border-radius: 18px;
        padding: 8px 10px;
        margin: 0 7.5px 15px 7.5px;
    }

    .str-chat__message-livestream-author {
        margin-bottom: 0px;
    }

    // remove default hover
    .str-chat__message-livestream:hover {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        cursor: pointer !important;
    }

    // gif size
    .str-chat__message-attachment {
        width: 60%;
        max-width: 200px;
        margin-top: 5px;
    }
    // giphy attribution
    .str-chat__message-attachment-card--content {
        // display: none;
        padding: 4px;
        font-size: 10px;
    }
    
    .str-chat__message-attachment-card__giphy-logo {
        height: 15px;
    }

    .str-chat__message-attachment-card--url {
        pointer-events: none;
    }

    // for reply quotes
    ${props => props.hasParent ? 
    `
        .str-chat__message-livestream-content { 
            border-radius: 0px 0px 18px 18px;
        }

        .str-chat__message-livestream-left {
            position: relative;
            top: -77px;
        }
    `
    :``}

    // overwrite avatar color
    .str-chat__avatar-fallback {
        background-color: hsl(${props => props.userColor[0]},${props => props.userColor[1]*100}%,50%);
    }
`

const MessageQuoteWrapper = styled.div`
    border-radius: 18px 18px 0px 0px;
    background-color: #262D31;
    padding: 7.5px;
    margin-left: 38px;
    margin-right: 8px;
`

const ReplyIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 30px;
    display: none;
`

const MessageInputWrapper = styled(Box)`
    box-shadow: 0px -2px 5px 0px rgba(82,82,82,0.8);
    background-color: #222;

    @media (max-width: 767px){
        position: sticky;
        width: 100%;
        bottom: 0;
    }
    
    .str-chat__small-message-input__wrapper {
        z-index: 1;
    }

    // hide attachment icon
    .str-chat__fileupload-wrapper {
        display: none;
    }
    // emoji icon {
    .str-chat__small-message-input-emojiselect {
        bottom: 14px;
    }
    // emoji popup
    .emoji-mart {
        background: #333333 !important;
        box-shadow: 2px 2px 5px 0px rgba(68,68,68,0.8);
    }

    // send icon
    .str-chat__send-button {
        bottom: 3px;
        position: relative;
    }
`
interface ReactionsBarProps {
    readonly messageInputFocused: boolean;
};

const ReactionsBar = styled.div<ReactionsBarProps>`
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;
    justify-content: center;
    overflow-x: auto;

    > div {
        padding: 0px !important;
        &:focus, &:active, &:hover {
            box-shadow: none;
        }
    }

    @media only screen and (max-width: 767px){
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 15px !important;
        padding-right: 30px !important;
        justify-content: left;
        display: ${props => props.messageInputFocused ? 'none' : 'flex'};
        > div {
            min-width: 40px;
            min-height: 40px;
        }
    }
`

const BadgeCounter = styled.div`
    background-color: red;
    color: rgb(255, 255, 255);
    position: absolute;
    top: -5px;
    right: -10px;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
`


const WatchPartyRoom:FC<{roomInfo: RoomInfo}> = ({roomInfo}) => {
    const [chatClient, setChatClient] = useState<StreamChat | null>(null);
    const [chatChannel, setChatChannel] = useState<ChatChannel | null>(null);
    const [members, setMembers] = useState<ChannelMemberResponse[]>([]);
    const [partyChannel, setPartyChannel] = useState<ChatChannel | null>(null);
    const [partyChannelWatcherCount, setPartyChannelWatcherCount] = useState(0);
    const [isModerator, setIsModerator] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const { width } = useWindowDimensions();
    const [isHeartsAppInited, setIsHeartsAppInited] = useState(false);
    const [heartsApp, setHeartsApp] = useState<any>(null);
    const [showGiphyKeyboard, setShowGiphyKeyboard] = useState(false);
    // const [currentGifSearchTerm, setCurrentGifSearchTerm] = useState('');
    const [currentMessageReplyingTo, setCurrentMessageReplyingTo] = useState<any>(null);
    const [waitingRoomMembers, setWaitingRoomMembers] = useState<any[]>([]);
    const [muteAndKickFunctionEnabled, setMuteAndKickFunctionEnabled] = useState<boolean>(false);
    const [messageInputFocused, setMessageInputFocused] = useState(false);
    const [hideChat, setHideChat] = useState(false);
    const [hideVideo, setHideVideo] = useState(false);
    // const [keyboardMarginOffset, setKeyboardMarginOffset] = useState(0);

    // const windowInitialHeight = window.innerHeight;

    const [error, setError] = useState<any>(null);
    
    useEffect(() => {
        const setupChatClient = async () => {  
            console.log('setting up chat client');          
            const client = new StreamChat(roomInfo.chat_user.stream_api_key);
            await client.setUser({id: roomInfo.chat_user.id}, roomInfo.chat_user.token);
            
            // start watching the channel
            const channel = client.channel('livestream', roomInfo.stream_channel_id);
            const watchResponse = await channel.watch();

            setMembers(watchResponse.members);

            channel.on('member.added', async (event) => {
                const text = `${event.member?.user?.name} has joined the room.`;
                channel.state.addMessageSorted({
                    text,
                    html: text,
                    __html: text,
                    type: 'ephemeral',
                    user: {id: roomInfo.chat_user.id},
                    // @ts-ignore
                    created_at: new Date(),
                    attachments: [],
                    mentioned_users: [],
                    reactions: [],
                    silent: true,
                });
            });

            //@ts-ignore
            channel.on('message.updated', event => {
                console.log(event);
            })

            channel.on(async () => {
                const queryResponse = await channel.queryMembers({});
                setMembers(queryResponse.members as any[]);
            });

            channel.on('message.updated', (event) => {
                console.log(event.message,'status');

                if(event.message?.status === 'received'){
                    gtag('event','select_content', {
                        'content_type': 'Buttons',
                        'item_id': 'MessagesReceived'
                    });
                }
            })

            console.log('setting up chat client complete');          
            setChatClient(client);
            setChatChannel(channel);

            // moderator check
            const queryResponse = await channel.queryMembers({id: roomInfo.chat_user.id, is_moderator:true});
            setIsModerator(queryResponse.members.length > 0);

            if (roomInfo.stream_admin_channel_id) {
                // join the admin channel
                const adminChannel = client.channel('livestream', roomInfo.stream_admin_channel_id);
                await adminChannel.watch();

                adminChannel.on('message.new', event => {
                    //console.log('adminChannel got message.new event', event);
                    if (event?.message?.text === 'WAITING_ROOM_MEMBERS_UPDATED') {
                        let waitingMembers = event?.message?.waiting_members as any[];
                        if (waitingMembers === null) {
                            waitingMembers = [];
                        }

                        setWaitingRoomMembers(waitingMembers);
                    }
                });
            }

            window.addEventListener('beforeunload', () => {
                const theFn = async () => {
                    await client.disconnect();
                };
                theFn();
            }, false);
        };

        try {
            setupChatClient();
        } catch (err) {
            console.error(err);
            setError(err);
        }
        
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (chatChannel && currentMessageReplyingTo) {
            const members = chatChannel?.state.members;
            const currentMessageReplyingToUserID = currentMessageReplyingTo.user.id;
            const member = members[currentMessageReplyingToUserID];
            if (member.is_moderator || member.role === 'moderator') {
                setMuteAndKickFunctionEnabled(false);
            } else {
                setMuteAndKickFunctionEnabled(true);
            }
        }
    }, [chatChannel, currentMessageReplyingTo]);

    // show toast when there are people in waiting room
    useEffect(() => {
        if(waitingRoomMembers && waitingRoomMembers.length > 0){
            Toast.toast(`There are ${waitingRoomMembers.length} people in the Waiting Room now.`);
        }
    },[waitingRoomMembers]);

    const [, sendHeartsSend] = useMachine(HeartSendingDebounceMachine, {
        actions: {
            clearContext: assign({
                HEART: 0,
                AMEN: 0,
                AMENPSPRINCE: 0,
                BAM: 0,
                CRY: 0,
                DSAM: 0,
                OHSLAIN: 0,
                PREACHIT: 0,
                OOOF: 0,
                WOW: 0,
                SOGOOD: 0,
                CLAPPING: 0
            }),
            addHearts: assign((context: any, event : any) => {
                const shape = event.shape;
                const objToReturn = {};
                // @ts-ignore
                objToReturn[shape] = (context[shape] ?? 0) + 1;
                return objToReturn;
            }),
            sendHearts: async (context, event) => {
                await partyChannel?.sendMessage({
                    wp_reactions: context,
                });
            },
        }
    });

    const heartsAppRef = useCallback(node => {
        if (isHeartsAppInited) return;
        if (!chatClient) return;

        if (node !== null) {
            setIsHeartsAppInited(true);
            const app = HeartsApp(node);
            setHeartsApp(app);

            const pc = chatClient!.channel('livestream', roomInfo.party.stream_channel_id);
            pc.watch();
            pc.on('message.new', event => {
                if (roomInfo.chat_user.id === event!.message!.user!.id) return;
                const reactions = event!.message!.wp_reactions;
                app.spamReactions(reactions);
            });

            pc.on(event => {
                if (event.watcher_count !== undefined) {
                    setPartyChannelWatcherCount(event.watcher_count);
                }
            });
            setPartyChannel(pc);
        }
    }, [isHeartsAppInited, chatClient, roomInfo.chat_user.id, roomInfo.party.stream_channel_id]);

    const onLikeButtonTapped = async (shape : any) => {
        heartsApp.addReaction(shape);
        gtag('event','select_content', {
            'content_type': 'Reactions',
            'item_id': shape
        });
        sendHeartsSend('HEART', {shape});
    };

    const deleteMessage = async () => {
        if (currentMessageReplyingTo) {
            Toast.toast('Deleting this message...');

            gtag('event','select_content', {
                'content_type': 'Moderation',
                'item_id': 'Delete Message'
            });

            await chatClient?.deleteMessage(currentMessageReplyingTo.id);
            setCurrentMessageReplyingTo(null);
            Toast.toast('Message deleted');
        }
    };

    const muteUser = async () => {
        if (currentMessageReplyingTo) {
            Toast.toast('Deleting this message and muting user...');

            gtag('event','select_content', {
                'content_type': 'Moderation',
                'item_id': 'Mute'
            });

            await chatClient?.deleteMessage(currentMessageReplyingTo.id);
            const csrfToken = await fetch('/api/csrf').then(res => res.json()).then(json => Promise.resolve(json.token));
            await fetch(`/api/rooms/${roomInfo.public_id}/muted-members`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    '_token': csrfToken,
                    'mute_chat_user_id': currentMessageReplyingTo.user.id,
                }),
            }).then(res => Promise.resolve(res.ok));
            Toast.toast('Successfully muted user');
        }

    };

    const kickUser = async () => {
        if (currentMessageReplyingTo) {
            Toast.toast('Deleting this message and banning user...');

            gtag('event','select_content', {
                'content_type': 'Moderation',
                'item_id': 'Ban'
            });
    
            await chatClient?.deleteMessage(currentMessageReplyingTo.id);
            await chatChannel?.banUser(currentMessageReplyingTo.user.id, {
                user_id: chatClient?.user?.id,
                reason: 'Kick',
            });

            setCurrentMessageReplyingTo(null);
            Toast.toast('Successfully kicked and banned user');
        }
    };

    const handleClickMessage = (message : any) => {
        setCurrentMessageReplyingTo(message);

        gtag('event','select_content', {
            'content_type': 'Buttons',
            'item_id': 'Click on Message'
        });
    }

    const MyMessageComponent:FC<any> = props => {
        if (props.isMyMessage && !props.isMyMessage() && props.message.is_user_muted === true) {
            return (
                <></>
            );
        }

        if (props.message.silent && !props.message.is_user_muted) {
            // for informational/status messages (xxx has joined the party)
            return (
                <Box pad={{vertical: '5px', horizontal: '2px'}} className="mb-2" style={{fontSize: '16px'}}>
                    {props.message.text}
                </Box>
            );
        }
    
        const parentMsg = props.message.wp_parent;
    
        return (
            <MessageWrapper onClick={() => handleClickMessage(props.message)} hasParent={parentMsg ? true : false} userColor={colorHash.hsl(props.message.user.id)}>
                {parentMsg && (
                    <MessageQuoteWrapper>
                        <ReplyQuote isParent userColor={colorHash.hsl(parentMsg.user.id)}>
                            <ReplyUser userColor={colorHash.hsl(parentMsg.user.id)}>{parentMsg.user.name}</ReplyUser>
                            {parentMsg.attachments && parentMsg.attachments[0] && parentMsg.attachments[0].thumb_url && (
                                <ReplyMessage>
                                    GIF <img src={parentMsg.attachments[0].thumb_url} alt="GIF" width="48" height="auto" />
                                </ReplyMessage>
                            )}
                            <ReplyMessage>{parentMsg.text}</ReplyMessage>
                        </ReplyQuote>
                    </MessageQuoteWrapper>
                )}
                <MessageLivestream {...props} handleOpenThread={() => setCurrentMessageReplyingTo(props.message)} />
                <ReplyIcon className="startWatchParty__replyIcon">
                    <FontAwesomeIcon icon={faReply}></FontAwesomeIcon>
                </ReplyIcon>
            </MessageWrapper>
        )
    };

    const messageInputOnFocus = () => {
        setMessageInputFocused(true);
        // setTimeout(function(){ 
        //     let keyboardHeight = windowInitialHeight - window.innerHeight;
        //     console.log(keyboardHeight, "focused height");
        //     setKeyboardMarginOffset(keyboardHeight);
        // }, 300);

        //window.scroll(0,1);
    }

    const messageInputOnBlur = () => {
        setMessageInputFocused(false);
        // setKeyboardMarginOffset(0);
    }

    const handleToggleChat = () => {
        setHideChat(!hideChat);
        gtag('event','select_content', {
            'content_type': 'buttons',
            'item_id': 'toggleChat'+hideChat
        });
    }

    const handleToggleVideo = () => {
        setHideVideo(!hideVideo);
        gtag('event','select_content', {
            'content_type': 'buttons',
            'item_id': 'toggleVideo'+hideVideo
        });
    }

    const handleToggleSettings = () => {
        setShowSettings(!showSettings);
        gtag('event','select_content', {
            'content_type': 'buttons',
            'item_id': 'toggleSettings'+!showSettings
        });
    }

    const handleToggleGiphyKeyboard = () => {
        setShowGiphyKeyboard(!showGiphyKeyboard);
        gtag('event','select_content', {
            'content_type': 'buttons',
            'item_id': 'toggleGiphyKeyboard'+!showGiphyKeyboard
        });        
    }
    
    if (chatClient && chatChannel) {
        return (
                <RowContainer className="row no-gutters" keyboardMarginOffset={0}>
                    <div className={hideChat ? 'col-12 d-flex flex-column justify-content-center align-items-center hideChat' : 
                            'col-md-8 d-flex flex-column justify-content-center align-items-center'}>
                        <div className={hideVideo ? 'hideVideo embed-responsive embed-responsive-16by9' : 'embed-responsive embed-responsive-16by9'}>
                            <iframe title="Stream" className="embed-responsive-item" width="560" height="315" src={roomInfo.party.video_url+'?playsinline=1&enablejsapi=1'} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <div ref={heartsAppRef} style={{pointerEvents: 'none', position: 'absolute', top: '0px', width: '100%', height: '100%' }}  />
                        </div>
                        <ReactionsBar messageInputFocused={messageInputFocused}>
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.HEART)} background={{"color": "#3A3A3A"}}>
                                <div className="reaction-button reaction-inner heart"></div>
                            </Box>
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.CRY)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner cry"></div>
                            </Box>
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.CLAPPING)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner clapping"></div>
                            </Box>
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.AMEN)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner amen"></div>
                            </Box>
                            {/* <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.PREACHIT)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner preachit"></div>
                            </Box> */}
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.BAM)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner bam"></div>
                            </Box>
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.WOW)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner wow"></div>
                            </Box>
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.SOGOOD)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner sogood"></div>
                            </Box>
                            <Box margin="small" round="full" pad="small" elevation="xsmall" onClick={() => onLikeButtonTapped(HeartShapes.DSAM)} background={{"color": "#3A3A3A"}} >
                                <div className="reaction-button reaction-inner dsam"></div>
                            </Box>
                            <span style={{width: '15px', display: 'block'}}>&nbsp;</span>
                        </ReactionsBar> 
                    </div>
                    {hideChat ?
                        <></>
                    :
                    <ChatContainer className="col-md-4" messageInputFocused={messageInputFocused} id="ChatContainer">
                            <Chat client={chatClient} theme={'livestream dark'}>

                                <Channel 
                                    Message={MyMessageComponent}
                                    channel={chatChannel}
                                    onLocalMessageAdded={() => setCurrentMessageReplyingTo(null)}
                                >
                                {showSettings ? 
                                    <Box fill>
                                        <ChannelHeaderWrapper messageInputFocused={messageInputFocused} onClick={() => handleToggleSettings()} className="livestream dark">
                                            <ChannelHeader live />
                                            <VIcon/>
                                            {partyChannelWatcherCount > 1 && (
                                                <PartyCount>in room | {partyChannelWatcherCount} in party</PartyCount>
                                            )}
                                            <SettingsIcon>
                                                <FontAwesomeIcon icon={faTimes} color="white" />
                                            </SettingsIcon>
                                        </ChannelHeaderWrapper>
                                        <Box fill style={{'overflowY':'scroll'}}>
                                            {isModerator && (
                                                <WaitingRoomModeratorView 
                                                    roomID={roomInfo['public_id'] as string}
                                                    members={waitingRoomMembers}
                                                />
                                            )}
                                            <MemberList
                                                currentChatUserID={roomInfo.chat_user.id}
                                                roomID={roomInfo.public_id}
                                                channel={chatChannel}
                                                enableModeratorFunctions={isModerator}
                                                members={members}
                                            />
                                        </Box>
                                    </Box>
                                :
                                    <>
                                        <Window hideOnThread>
                                            <ChannelHeaderWrapper messageInputFocused={messageInputFocused}>
                                                <ChannelHeader live />
                                                <VIcon onClick={() => handleToggleSettings()}/>
                                                {partyChannelWatcherCount > 1 && (
                                                    <PartyCount>in room | {partyChannelWatcherCount} in party</PartyCount>
                                                )}
                                                <HideIcon className="d-block d-md-none" >
                                                    <Menu
                                                        size="small"
                                                        margin="none"
                                                        icon={<FontAwesomeIcon icon={faWindowMaximize}/>}
                                                        dropAlign={{"top": "top", "right": "right"}}
                                                        items={
                                                            [
                                                                { label: 'Hide Chat', onClick: () => handleToggleChat() },
                                                                { label: hideVideo ? 'Show Video' : 'Hide Video', onClick: () => handleToggleVideo() },
                                                            ]
                                                        }
                                                    />
                                                </HideIcon>
                                                <HideIcon className="d-none d-md-block" onClick={() => handleToggleChat()}>
                                                    <FontAwesomeIcon icon={faWindowMaximize} color="white" />
                                                </HideIcon>
                                                <SettingsIcon onClick={() => handleToggleSettings()}>
                                                    {waitingRoomMembers && waitingRoomMembers.length > 0 && (
                                                        <BadgeCounter>{waitingRoomMembers.length}</BadgeCounter>
                                                    )}
                                                    <FontAwesomeIcon icon={faCog} color="white" />
                                                </SettingsIcon>
                                            </ChannelHeaderWrapper>
                                            <MessageList />
                                            {currentMessageReplyingTo && (
                                                <ReplyModal>
                                                    Replying to 
                                                    <ReplyModalClose>
                                                        {((isModerator && muteAndKickFunctionEnabled) || (currentMessageReplyingTo.user.id === roomInfo.chat_user.id)) && (                                                            
                                                            <Menu
                                                                label="More"
                                                                size="small"
                                                                margin="none"
                                                                icon={<FontAwesomeIcon icon={faEllipsisH}/>}
                                                                dropAlign={{"bottom": "bottom", "right": "right"}}
                                                                items={
                                                                (isModerator && muteAndKickFunctionEnabled) ?
                                                                    [
                                                                        { label: 'Delete Message', onClick: deleteMessage },
                                                                        { label: 'Delete Message & Mute User', onClick: muteUser },
                                                                        { label: 'Ban and remove user', onClick: kickUser }
                                                                    ]
                                                                    :
                                                                    (currentMessageReplyingTo.user.id === roomInfo.chat_user.id) ?
                                                                    [
                                                                        { label: 'Delete Message', onClick: deleteMessage }
                                                                    ]
                                                                : []
                                                                }
                                                            />
                                                        )}
                                                        <ReplyModalCloseIcon onClick={() => setCurrentMessageReplyingTo(null)}>
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </ReplyModalCloseIcon>
                                                    </ReplyModalClose>
                                                    <ReplyQuote isParent={false} userColor={colorHash.hsl(currentMessageReplyingTo.user.id)}>
                                                        <ReplyUser userColor={colorHash.hsl(currentMessageReplyingTo.user.id)}>{currentMessageReplyingTo.user.name}</ReplyUser> 
                                                        {currentMessageReplyingTo.attachments && currentMessageReplyingTo.attachments[0] && currentMessageReplyingTo.attachments[0].thumb_url && (
                                                            <ReplyMessage>
                                                                GIF <img src={currentMessageReplyingTo.attachments[0].thumb_url} alt="GIF" width="48" height="auto" />
                                                            </ReplyMessage>
                                                        )}
                                                        <ReplyMessage>{currentMessageReplyingTo.text}</ReplyMessage>
                                                    </ReplyQuote>
                                                </ReplyModal>
                                            )}
                                            <MessageInputWrapper direction="row">
                                                <GiphyKeyboardIcon onClick={() => handleToggleGiphyKeyboard()} />
                                                <MessageInput Input={MessageInputSmall} parent={currentMessageReplyingTo} 
                                                focus={currentMessageReplyingTo ? true : false} 
                                                additionalTextareaProps={{'id': 'MessageInput', 'onFocus': () => messageInputOnFocus(), 'onBlur': () => messageInputOnBlur()}} 
                                                />
                                            </MessageInputWrapper>

                                            {showGiphyKeyboard && (
                                                    <GiphyKeyboard
                                                        apiKey={roomInfo.giphy_key}
                                                        onClose={() => handleToggleGiphyKeyboard()}
                                                        width={width >= 768 ? width/12*4 - 45 : width-45}
                                                        onSelect={async (item : any) => {
                                                            await chatChannel.sendMessage({
                                                                text: '',
                                                                command: 'giphy',
                                                                command_info: {
                                                                    name: 'Giphy',
                                                                },
                                                                attachments: [{
                                                                    type: 'giphy',
                                                                    thumb_url: `https://media0.giphy.com/media/${item.id}/giphy.gif`,
                                                                    // title: currentGifSearchTerm.length > 0 ? currentGifSearchTerm : item.title,
                                                                    title_link: item.url
                                                                }],
                                                            });
                                                        }}
                                                        seed={roomInfo.gifs}
                                                    />  
                                            )}
                                        </Window>
                                        <Thread fullWidth />
                                    </>
                                }
                                </Channel>
                            </Chat>
                    </ChatContainer>
                    } 
                    {hideVideo && (
                        <VideoMinimized onClick={() => handleToggleVideo()}>
                            Show Video <FontAwesomeIcon icon={faExpandAlt} color="white" />
                        </VideoMinimized>
                    )}

                    {hideChat && (
                        <ChatMinimized onClick={() => handleToggleChat()}>
                            Chat <FontAwesomeIcon icon={faExpandAlt} color="white" />
                        </ChatMinimized>
                    )}
                </RowContainer>
        );
    } else if (error) {
        return (
            <Box height="100vh">
                <Box fill pad="medium" align="center" justify="center">
                    A (probably transient) error occurred attempting to load the room. Please refresh the page to try again.
                </Box>
            </Box>
        );
    } else {
        return (
            <Box height="100vh">
                <Box fill pad="medium" align="center" justify="center" className="text-center">
                    <FontAwesomeIcon icon={faSpinner} spin className="mb-2"/>
                    You have entered the room {roomInfo.name}. <br/>Enjoy the party!
                </Box>
            </Box>
        );
    }
};

export default WatchPartyRoom;
