import React, { FC } from 'react';
import { Box, Heading } from 'grommet';
import Toast from '../common/toast';
import ButtonWithState from '../common/ButtonWithState';
interface IMember {
    id: number,
    name: string
}

export interface IWaitingRoomProps {
    roomID: string,
    members: any[],
}

const WaitingRoom:FC<IWaitingRoomProps> = props => {
    const admitMember = async (member: IMember) => {
        const csrfToken = await fetch('/api/csrf').then(res => res.json()).then(json => Promise.resolve(json.token));
        const success = await fetch(`/api/rooms/${props.roomID}/waiting/${member.id}/admit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'_token': csrfToken}),
        }).then(res => Promise.resolve(res.ok));

        if (success) {
            Toast.toast(`Successfully admitted ${member.name}!`);
        } else {
            Toast.toast(`There was an error admitting ${member.name}!`);
        }
    };
    
    return (
        <Box pad="medium" style={{minHeight: 'initial'}}>
            <Heading level="5">In the Waiting Room</Heading>
            {props.members.map((member: IMember, index) => {
                return (
                    <Box direction="row" justify="between" key={member.name} responsive={false} style={{minHeight: '44px'}} background={index % 2 === 0 ? { color: "#333"} : {}} pad={{vertical: '5px', horizontal: '10px'}}>
                        <Box alignSelf="center" responsive={false}>{member.name}</Box>
                        <ButtonWithState onClick={() => admitMember(member)} label="Admit" />
                    </Box>
                );
            })}
            {props.members.length === 0 && (
                <div>
                    There's nobody in the waiting room.
                </div>
            )}
        </Box>
    );
};

export default WaitingRoom;
