import React, { FC } from 'react';
import { Box, Heading, Text } from 'grommet';
import { RoomInfo } from '../types';
import VIcon from '../common/vIcon';

const WaitingRoom:FC<{roomInfo: RoomInfo}> = ({roomInfo}) => {
    return (
        <Box height="100vh" align="center" justify="center" background={{"image": "url(/images/background.jpg)", "repeat": "no-repeat", "size": "cover"}}>
            <Box pad="large" align="center" justify="center" background={{"color": "#3A3A3A"}} border="bottom" width="medium">
                <Heading margin="none" level="4"><VIcon />{roomInfo.name}</Heading>
            </Box>
            <Box pad="large" align="center" justify="center" background={{"color": "#3A3A3A"}} width="medium">
                <Text textAlign="center">You are in the waiting room. Please wait for a moderator to let you in.</Text>
            </Box>
        </Box>
    );
};

export default WaitingRoom;
