import React, { useContext, useEffect, useState } from 'react';
import {
    Grid, // our UI Component to display the results
    SearchBar, // the search bar the user will type into
    SearchContext, // the context that wraps and connects our components
    SearchContextManager, // the context manager, includes the Context.Provider
} from '@giphy/react-components'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import gtag from 'ga-gtag';

const GiphyKeyboardWrapper = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #333;
    padding: 45px 15px 15px 15px;
    z-index: 100;
    height: 50vh;
    max-height: 50vh;
`

const GiphyKeyboardCloseIcon = styled.span`
    position: absolute;
    top: 15px;
    right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
`

const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const GridContainer = styled.div`
    max-height: calc(50vh - 140px);
    overflow-y: scroll;
    overflow-x: hidden;
`

const SuggestionBarContainer = styled.div`
    display: flex;
    color: white;
    flex-direction: row;
    font-weight: 600;
    font-size: 14px;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
`

const TrendingSearchPillContainer = styled.div`
    background: #000;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    margin-right: 10px;
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 20px;
`

const SuggestionBar = ({suggestedWords}) => {
    return (
        <SuggestionBarContainer>
            {suggestedWords.map((string, index) => 
                <SuggestionPills key={index} string={string} />
            )}
        </SuggestionBarContainer>
    )
}

const SuggestionPills = (props) => {
    const { setSearch } = useContext(SearchContext)
    return (
        <TrendingSearchPillContainer onClick={() => setSearch(props.string)}>
            {props.string}
        </TrendingSearchPillContainer>
    )
}

// the search experience consists of the manager and its child components that use SearchContext
const SearchExperience = (props) => {
    return (
        <SearchContextManager apiKey={props.apiKey} initialTerm={props.initialTerm}>
            <Components seed={props.seed} onSelect={props.onSelect} width={props.width} />
        </SearchContextManager>
    )
}

// define the components in a separate function so we can
// use the context hook. You could also use the render props pattern
const Components = (props) => {
    const { fetchGifs, term, searchKey } = useContext(SearchContext);

    useEffect(() => {
        if(searchKey && searchKey !== ''){
            gtag('event','select_content', {
                'content_type': 'GiphySearch',
                'item_id': searchKey
            });
        }
    }, [searchKey]);

    return (
        <SearchContainer>
            <SearchBar theme={{mode: 'dark'}} />
            <SuggestionBar suggestedWords={props.seed.map(category => category.name)} />
            <GridContainer>
                <Grid
                    key={searchKey}
                    columns={props.width >= 350 ? 3 : 2}
                    gutter={10}
                    width={props.width}
                    fetchGifs={() => fetchGifs(12).then(fetchResult => {
                        const filteredSeed = props.seed.filter(category => category.name.toLowerCase() === term.toLowerCase());
                        if (filteredSeed.length > 0) {
                            const filteredCategory = filteredSeed[0];
                            const filteredGifs = filteredCategory.gifs.map(gif => gif.gif_json);
                            fetchResult.data = filteredGifs.concat(fetchResult.data);
                        }
                        
                        return fetchResult;
                    })}
                    onGifClick={props.onSelect}
                    hideAttribution
                />
            </GridContainer>
        </SearchContainer>
    )
}

const GiphyKeyboard = (props) => {
    const [showLoading, setShowLoading] = useState(false);

    const onSelect = (gif,e) => {
        e.preventDefault();
        props.onSelect(gif);
        setShowLoading(true);
        setTimeout(() => props.onClose(), 777);
    }

    return (
        <GiphyKeyboardWrapper>
            <GiphyKeyboardCloseIcon onClick={() => props.onClose()}>
                <FontAwesomeIcon icon={faTimes} color="white" />
            </GiphyKeyboardCloseIcon>
            {showLoading ? 
                <div style={{textAlign: 'center', fontSize: '32px'}}>
                    <FontAwesomeIcon icon={faSpinner} spin /> 
                </div>
            :
                <SearchExperience
                    apiKey={props.apiKey}
                    onSelect={onSelect}
                    initialTerm={props.seed[0] ? props.seed[0].name : 'Wow'}
                    width={props.width || 300}
                    seed={props.seed}
                />
            }
        </GiphyKeyboardWrapper>
    )
}

export default GiphyKeyboard;
