import styled from 'styled-components';

const VIcon = styled.div`
    display: inline-block;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    background: url('/images/vlogo.jpg');
    background-size: contain;
    margin-right: 10px;
    vertical-align: middle;
`

export default VIcon;