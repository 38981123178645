import React, { FC, useState, useEffect } from 'react';
import { Box, Heading, Button, TextInput, FormField, Paragraph } from 'grommet';
import Toast from '../common/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import VIcon from '../common/vIcon';
import gtag from 'ga-gtag';

const Gate:FC<{roomInfo: {id?: string, name?: string}, onSuccessfulAuth: (data: any) => void}> = ({roomInfo, onSuccessfulAuth}) => {
    const [name, setName] = useState('');
    const [pin, setPin] = useState('');
    
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    useEffect(() => {
        setSubmitEnabled(name.length > 0 && pin.length > 0 && !isSubmitting);
    }, [name, pin, isSubmitting]);

    const onSubmit = async (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            gtag('event', 'join_group', {
                'group_id': roomInfo.name
            });

            const csrfToken = await fetch('/api/csrf').then(res => res.json()).then(json => Promise.resolve(json.token));
            const authResponse = await fetch(`/api/rooms/${roomInfo.id}/enter`, {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    _token: csrfToken,
                    name,
                    pin,
                }),
            });

            const json = await authResponse.json();
            if (!authResponse.ok) {
                for (let [key, value] of Object.entries(json.errors)) {
                    Toast.toast(key + ':' + value);
                }
                setIsSubmitting(false);
            } else {
                setIsSubmitting(false);
                onSuccessfulAuth(json);
            }
        } catch (err) {
            console.log('err is', err);
            setIsSubmitting(false);
        }
    };

    return (
        <Box height="100vh" align="center" justify="center" background={{"image": "url(/images/background.jpg)", "repeat": "no-repeat", "size": "cover"}}>
            <Box pad="medium" background={{"color": "#3A3A3A"}} border="bottom" width="medium">
                <Heading margin="none" level="4"><VIcon/>{roomInfo.name}</Heading>
            </Box>
            <Box pad="medium" align="center" justify="center" background={{"color": "#3A3A3A"}} width="medium">
                <form>
                    <div>
                        <FormField label="Enter your name">
                            <TextInput 
                                name="name"
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={event => {
                                    setName(event.target.value);
                                }}
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField label="Room PIN">
                            <TextInput 
                                name="name"
                                type="password"
                                placeholder="xxxxxx"
                                value={pin}
                                onChange={event => {
                                    setPin(event.target.value);
                                }}
                            />
                        </FormField>
                    </div>
                    <Paragraph size="small" margin={{"vertical":"medium"}}>Don’t have a PIN? Approach your caregroup leader for assistance.</Paragraph>
                    <Button primary label="Join Room" 
                        reverse
                        icon={isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> : <></>}
                        fill="horizontal"
                        size="large"
                        type="submit"
                        onClick={onSubmit}
                        disabled={!submitEnabled}
                    />

                </form>
                <p style={{display: 'none'}}>{JSON.stringify(roomInfo)}</p>
            </Box>
        </Box>
    );
};

export default Gate;
